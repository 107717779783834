import Api from "./Api";

const headers = {
'X-Requested-With': 'XMLHttpRequest',
'Authorization':`Bearer ${localStorage.getItem('token')}` 
}
export default{
    async getAllUsers(){
        var response = await Api().get('user',{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async editUser(data: any){
        var response = await Api().put(`user/${data.id}`, data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async login(data: any){
        var response = await Api().post('login',data);
        return response.data;
    },
    async addUser(data: any){
        var response = await Api().post('user', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deleteUser(data: any){
        var response = await Api().delete(`user/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllProject(){
        var response = await Api().get('project',{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllProjectList(){
        let userId = JSON.parse(localStorage.getItem('user'))?.id
        var response = await Api().get(`/user/projectsForFilter/${userId}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllTeamLeadProject(){
        let userId = JSON.parse(localStorage.getItem('user'))?.id
        var response = await Api().get(`project/teamLead/${userId}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllUsersProject(id){
        let userId = JSON.parse(localStorage.getItem('user'))?.id
        var response = await Api().get(`project/${id}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async editProject(data: any){
        var response = await Api().put(`project/${data.id}`, data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async addProject(data: any){
        var response = await Api().post('project', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deactivateProject(data: any){
        var response = await Api().put(`project/deActivate/${data}`,{},{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deleteProject(data: any){
        var response = await Api().delete(`project/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async activeProject(data: any){
        var response = await Api().put(`project/activate/${data}`,{},{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllWorklog(params){
        var response = await Api().get('workLog/filter',{
            params: params,
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` },
            paramsSerializer: { indexes: null }
        });
        return response.data;
    },
    async getUserAllWorklog(params){
        var response = await Api().get('workLog/user/filter',{
            params: params,
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` },
            paramsSerializer: { indexes: null }
        });
        return response.data;
    },
    async getTeamLeadAllWorklog(params){
        var response = await Api().get('workLog/tl/filter',{
            params: params,
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` },
            paramsSerializer: { indexes: null }
        });
        return response.data;
    },
    async editWorklog(data: any){
        var response = await Api().put(`workLog/${data.id}`, data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async addWorklog(data: any){
        var response = await Api().post('workLog', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deleteWorklog(data: any){
        var response = await Api().delete(`workLog/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async userVerification(data: any){
        var response = await Api().post('user/verify', data,{
        });
        return response.data;
    },
    async resendEmailVerification(data: any){
        var response = await Api().post('user/resendVerificationLink', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async forgotPassword(data: any){
        var response = await Api().post('user/forgotPassword', data);
        return response.data;
    },
    async resetPassword(data: any){
        var response = await Api().post('user/resetPassword', data);
        return response.data;
    },
    async getWorklogById(params){
        var response = await Api().get('workLog',{
            params: params,
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
           
        });
        return response.data;
    },
    async getVerifiedUsers(){
        var response = await Api().get('verifiedUsers',{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getVerifiedTeamLeadUsers(){
        let userId = JSON.parse(localStorage.getItem('user'))?.company.id
        var response = await Api().get(`/verifiedUsers/company/${userId}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getVerifiedUserFilter(){
        let userId = JSON.parse(localStorage.getItem('user'))?.id
        var response = await Api().get(`user/tl/verifiedUsers/${userId}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getAllCompany(){
        var response = await Api().get('company',{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async editCompany(data: any){
        var response = await Api().put(`company/${data.id}`, data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async addCompany(data: any){
        var response = await Api().post('company', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deleteCompany(data: any){
        var response = await Api().delete(`company/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getHoliday(params){
        var response = await Api().get('holiday',{
            params: params,
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` },
            paramsSerializer: { indexes: null }
        });
        return response.data;
    },
    async addHoliday(data: any){
        var response = await Api().post('holiday', data,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async deleteHoliday(data: any){
        var response = await Api().delete(`holiday/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
    async getUser(data: any){
        var response = await Api().get(`user/${data}`,{
            headers: {'X-Requested-With': 'XMLHttpRequest',
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        });
        return response.data;
    },
}