import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, Row, Col, FormFeedback } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import enGb from 'date-fns/locale/en-GB';
import moment from "moment";
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addWorklog, editWorklog, fetchHolidayList, fetchProjectList ,fetchUsersProject} from '../store/UserAction';
import '../components/Table/Table.css';
var qs = require('qs');

registerLocale('en-gb', enGb);

// const holidayList = [
//   {
//     "id": 3,
//     "fromDate": "1/10/2024",
//     "toDate": "2/10/2024",
//     "noOfDays": 1.5
// },
// {
//     "id": 1,
//     "fromDate": "24/09/2024",
//     "toDate": "26/09/2024",
//     "noOfDays": 1.0
// }
// ]
type Props = {
  formType : string;
  rowData ?: any;
  closeModal ?: any;
  projectListEdit ?: any;
}
type FormDataProps ={
  user: any;
  name ?: any;
  taskName : string;
  lead ?: any;
  workLogType ?: any;
  project ?: any;
  location ?: any;
  startDate ?: any;
  date ?: any;
  timeSpent ?: any;
  description ?: any;
  id ?: any;
  break ?: any;
}
export const AddWorklog: React.FC<Props>  = ({formType,rowData,closeModal}: Props) => {
  const [data, setData] =useState<FormDataProps>(rowData);
  const [projectValid, setProjectValid] = useState(false)
  const [taskValid, setTaskValid] = useState(false)
  const [timeValid, setTimeValid] = useState(false)
  const [locationValid, setLocationValid] = useState(false)
  const [billableValid, setBillableValid] = useState(false)
  const [dateValid, setDateValid] = useState(false)
  const dispatch= useAppDispatch()
  const projectListByUsers = useAppSelector(state=>state.user.projectListByUsers)
  const getProjectListByUser = useAppSelector(state=>state.user.getProjectListByUser)
  const [project, setProject] = useState([])
  const [projectList, setProjectList] = useState([])
  const [location, setLocation] = useState([])
  const [workLogType, setBillable] = useState([])
  const loading = useAppSelector(state=>state.user.loading);
  const [isOpenProject,setIsOpenProject] = useState(false)
  const [isOpenlocation,setIsOpenLocation] = useState(false)
  const [isOpenBillable,setIsOpenBillable] = useState(false)
  const [showErr, setShowErr] = useState(false)
  const [disabledFieldTime,setDisabledFieldTime] = useState(false)
  const [disabledFieldDate,setDisabledFieldDate] = useState(false)
  const [disabledFieldTask,setDisabledFieldTask] = useState(false)
  const [disabledFieldBillable,setDisabledFieldBillable] = useState(false)
  const [disabledFieldLocation,setDisabledFieldLocation] = useState(false)
  const [disabledFieldComments,setDisabledFieldComments] = useState(false)
  const [remainingHrs,setRemainingHrs] = useState('')
  const [errMsg, setErrMsg] = useState({date: '' ,time: ''})
  const [days, setDays] = useState()
  const holiday = useAppSelector(state=>state.user.holidayList)
  const getHolidayList = useAppSelector(state=>state.user.getHolidayList)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [holidayList, setHolidayList] = useState([])

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
 useEffect(() =>{
    const currentYear = new Date().getFullYear();
    let arrayLocation = data?.location?.split(" ");
    let myLocation = arrayLocation?.map((str, index) => ({ name: str, id: index + 1 }));
    setLocation(myLocation)
    let arrayBillable = data?.workLogType?.split(" ");
    let myBillable = arrayBillable?.map((str, index) => ({ name: str, id: index + 1 }));
    setBillable(myBillable)
    
    if(getHolidayList){
      setHolidayList(holiday)
    }
 
    if(getProjectListByUser){
      if(formType === 'edit'){
        const newProjectList = projectListByUsers && projectListByUsers.filter(item => item.id === data.project.id)
        setRemainingHrs(newProjectList.length > 0 ? newProjectList[0]['remainingHrs'] : '')
        setProject(projectListByUsers)
      }
      else{
        setProject(projectListByUsers)
      }
    }
    if(formType === 'edit'){
      dispatch<any>(fetchUsersProject(data.user.id));
      setProject(projectListByUsers)
      setDisabledFieldDate(true)
      setDisabledFieldBillable(true)
      setDisabledFieldComments(true)
      setDisabledFieldLocation(true)
      setDisabledFieldTask(true)
      setDisabledFieldTime(true)
    }
    else if(formType === 'add'){
      let userId = JSON.parse(localStorage.getItem('user'))?.id
      dispatch<any>(fetchUsersProject(userId));
      setProject(projectListByUsers)
    }
    let obj = {
      fromDate :formatDate(new Date(currentYear, 0, 1)), 
      toDate: formatDate(new Date(currentYear, 11, 31)),
      userId: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
    }
    const queryParam = qs.parse(obj);
    dispatch<any>(fetchHolidayList(queryParam));
    setHolidayList(holiday)
  },[dispatch,getProjectListByUser,getHolidayList])

const onSubmit = (e) =>{
  e.preventDefault()
  let flag = false
  if(data?.project === undefined){
   flag = true
   setProjectValid(true)
 }
 if((data?.timeSpent == undefined)){
   flag = true
   setTimeValid(true)
 }
 if((data?.taskName == undefined)){
   flag = true
   setTaskValid(true)
 }
 if((data?.date == null)){
  flag = true
  setDateValid(true)
}
 if((data?.location?.length === 0) || (data?.location == undefined) || (location.length === 0)){
   flag = true
   setLocationValid(true)
 }
 if((data?.workLogType?.length === 0) || (data?.workLogType == undefined)){
  flag = true
  setBillableValid(true)
}
 if(showErr === true){
  flag = true
 }
 if(!flag){
  if(formType === 'edit'){
    let editObj = {
      taskName : data?.taskName,
      project : {
        id : data?.project.id
      },
     description: data?.description ? data?.description : '',
     location: data?.location,
     workLogType: data?.workLogType,
     date: data?.date,
     timeSpent: data?.timeSpent,
     id: data?.id
    }
    dispatch<any>(editWorklog(editObj));
  }
  else{
    let obj = {
      taskName : data?.taskName,
      project : {
        id : data?.project.id
      },
      user: {
        id: JSON.parse(localStorage.getItem('user')).id
     },
     description: data?.description ? data?.description : '',
     location: data?.location,
     workLogType: data?.workLogType,
     date: data?.date,
     timeSpent: data?.timeSpent
    }
    dispatch<any>(addWorklog(obj));
  }
 }
}
const handleLocationClick = (e) =>{
  if(e.length === 0 || data.location === ''){
    setData({...data, location: []})
    setLocation([])
    setDisabledFieldBillable(false)
  }
  else{
    setLocation(e)
    setData({ ...data, location : e[0].name }); 
    setDisabledFieldBillable(true)
  }
    setLocationValid(false)
}
const handleProjectClick = (e) =>{
  if(e.length === 0 || data?.project === undefined ){
    setData({...data, project: []})
    setErrMsg({...errMsg , time: '',date:''})
    setProjectList([])
    setDisabledFieldDate(disabledFieldDate)
    setDisabledFieldBillable(disabledFieldBillable)
    setDisabledFieldComments(disabledFieldComments)
    setDisabledFieldLocation(disabledFieldLocation)
    setDisabledFieldTask(disabledFieldTask)
    setDisabledFieldTime(disabledFieldTime)
  }
  else{
    setProjectList(e)
    setRemainingHrs(e[0].remainingHrs)
    setData({ ...data, project : e[0]}); 
    setDisabledFieldDate(true)
  }
   
  setProjectValid(false)
}
const handleBillableClick = (e) =>{
  if(e.length === 0 || data.workLogType === ''){
    setData({...data, workLogType: []})
    setBillable([])
    setDisabledFieldComments(false)
  }
  else{
    setBillable(e)
    setData({ ...data, workLogType :e[0].name }); 
    setDisabledFieldComments(true)
  }
  setBillableValid(false)
  }
   
   
const handleTask = (e) =>{
  if(e.target.value !== '' || data.taskName !== undefined){
    setTaskValid(false)
    setDisabledFieldLocation(true)
  }
  else{
    setDisabledFieldLocation(false)
  }

 setData({ ...data, taskName : e.target.value }); 
}

const compareTimes = (time, givenTime) => {
  const [inputHours, inputMinutes] = time.split(':').map(Number);
  const [givenHours, givenMinutes] = givenTime.split(':').map(Number);

  if (inputHours > givenHours || (inputHours === givenHours && inputMinutes > givenMinutes)) {
    setErrMsg({...errMsg , time:'You have entered hours, which exceeds the remaining hours.'})
    setShowErr(true)
    setDisabledFieldTask(false)
  } else {
    setShowErr(false)
    setErrMsg({...errMsg , time:''})
    setDisabledFieldTask(true)
  }
};
const handleTime = (e) =>{
  let time1 = e.target.value
  if(formType === 'edit'){
  const editData  = projectListByUsers.find(item => item.id === data.project.id)
  const checkProjectExist  = projectListByUsers.filter(item => item.id === data.project.id)
  if(checkProjectExist.length > 0){
    if(e !== '' || data.timeSpent !== undefined){
      if(projectListByUsers !== undefined && projectListByUsers.length !== 0){
        compareTimes(time1, editData['remainingHrs']);
      }
      else{
        compareTimes(time1, data.project.remainingHrs);
      }
      setTimeValid(false)
    }
  }
}
  else{
    compareTimes(time1, data.project.remainingHrs);
  }
 setData({ ...data, timeSpent : e.target.value }); 
}
const handleComments = (e) =>{
  setData({ ...data, description : e.target.value }); 
}
const parseDate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};
const handleDateChange = (date) =>{
  if(date) {
    setDateValid(false)
    let data1 = new Date(date)
    let newDate = data1 && data1.toLocaleDateString('en-GB')
    project.filter(item => {
      const startDate = parseDate(item.startDate);
      const endDate = parseDate(item.endDate);
      const currentDate = parseDate(newDate);
      if(item.id === data.project.id){
      if (currentDate < startDate || currentDate > endDate) {
        setErrMsg({...errMsg , date:'Selected date should be between projects start and end date.Please select another date.'})
        setShowErr(true)
        setDisabledFieldTime(false)
      }
      else{
        setShowErr(false)
        setErrMsg({...errMsg , date:''})
        setDisabledFieldTime(true)
      }
    }
    });
    setData({...data , date: newDate})
  }
  else {
   setData({ ...data, date : null }); 
   setDisabledFieldTime(false)
  }
}
const closeOutsideProject = () =>{
  setIsOpenProject(false)
}
const handleOpenProject = () =>{
  setIsOpenProject(true)
}
const closeOutsideBillable = () =>{
  setIsOpenBillable(false)
}
const handleOpenBillable = () =>{
  setIsOpenBillable(true)
}
const closeOutsideLocation = () =>{
  setIsOpenLocation(false)
}
const handleOpenLocation = () =>{
  setIsOpenLocation(true)
}
const parseDate1 = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  return new Date(year, month - 1, day);  // Month is zero-indexed in JavaScript
};
// Function to disable weekends
const isWeekday = (date) => {
  const day = date.getDay();
    return day === 0 || day === 6;  // Sunday (0) and Saturday (6)
};
  // Check if a date should be disabled based on dateRanges
  const isDateDisabled = (date) => {
    return holidayList.some(range => {
      const from = parseDate1(range.fromDate);
      const to = parseDate1(range.toDate);
      return date >= from && date <= to;
    });
  };

  // Combined filter function to disable weekends and date ranges
  const filterDate = (date) => {
    return !isWeekday(date) && !isDateDisabled(date);  // Disable if weekend or in dateRanges
  };
const Location =[{id: '1', name: 'On-Site'},{id: '2', name: 'Off-Site'}]
const Billable =[{id: '1', name: 'Billable'},{id: '2', name: 'Non-Billable'}]
  return (
    <Fragment>
       <Form onSubmit={onSubmit}>
       <Row>
         <Col>
            <FormGroup>
                <Label for="examplePassword">
                  Select Project
                </Label>
                <Typeahead
                  isInvalid = {projectValid}
                  className='dropdownComp'
                  id="basic-typeahead-single"
                  labelKey="name"
                  onFocus={handleOpenProject}
                  onBlur={closeOutsideProject}
                  open= {isOpenProject}
                  onChange={handleProjectClick}
                  options={projectListByUsers}
                  clearButton
                  placeholder="Select Project"
                  selected={data?.project?.name ? [data?.project['name']] : projectList}
                />
                  <FormFeedback>
                    Please select project
                 </FormFeedback>
            </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                <Label for="examplePassword">
                 Remaining hours
                </Label>
                <Input
                        value = {data?.project !== undefined ? remainingHrs: ''}
                        disabled={true}
                        invalid={timeValid}
                        onChange={handleTime}
                        id="exampleEmail"
                        name="name"
                        placeholder={data?.project !== undefined ? data?.project.remainingHrs : ''}
                        type="text"
                        />
                  <FormFeedback>
                    Please select project
                 </FormFeedback>
            </FormGroup>
            </Col>
            </Row>
            <Row>
                  <Col>
                  <FormGroup className={dateValid ? "worklogInput" : ''}>
                <Label for="examplePassword">
                  Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                 </Label>
                <DatePicker 
                id='date'
                disabled ={disabledFieldDate === false ?  true : false}
                locale="en-gb"
                selected={data?.date && new Date((moment(data?.date, 'DD/MM/YYYY').format()))} 
                isClearable
                onChange={(date) => handleDateChange(date)} 
                filterDate={filterDate}
                dateFormat="dd/MM/yyyy"/>
                { dateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                    Please select date
                 </span>}
            </FormGroup>
            </Col>
            <Col>
                      <FormGroup className={timeValid ? "worklogInput" : ''}>
                          <Label for="examplePassword">
                            Time Spent <span style={{color: '#A0A0A0'}}>(HH:MM)</span>
                        </Label>
                        <Input
                        value = {data?.timeSpent}
                        disabled={disabledFieldTime === false ?  true : false}
                        invalid={timeValid}
                        onChange={handleTime}
                        id="exampleEmail"
                        name="name"
                        placeholder="Enter Time Spent"
                        type="time"
                        />
                        <FormFeedback>
                          Please enter time spent
                        </FormFeedback>
                      </FormGroup>
                  </Col>
            </Row>
            <FormGroup>
            <Label for="examplePassword">
                 Enter Task
                </Label>
                <Input
                disabled ={disabledFieldTask === false ?  true : false}
                invalid={taskValid}
                value = {data?.taskName}
                id="exampleEmail"
                name="name"
                placeholder="Enter Task Name"
                onChange={handleTask}
                type="text"
                />
                <FormFeedback>
                  Please enter task
                </FormFeedback>
            </FormGroup>
               
            <Row>
                  <Col>
                  <FormGroup className={locationValid ? "worklogInput" : ''}>
                        <Label for="examplePassword">
                             Location
                        </Label>
                        <Typeahead
                        isInvalid={locationValid}
                        disabled={disabledFieldLocation === false ?  true : false}
                        className='dropdownComp'
                        id="basic-typeahead-single"
                        labelKey="name"
                        onChange={handleLocationClick}
                        options={Location}
                        clearButton
                        onFocus={handleOpenLocation}
                        onBlur={closeOutsideLocation}
                        open= {isOpenlocation}
                        placeholder="Select Location"
                        selected={location}
                        />
                        { billableValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                          Please select location
                        </span>}
                        
                        </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup className={billableValid ? "worklogInput" : ''}>
                        <Label for="examplePassword">
                             Enter Billable
                        </Label>
                        <Typeahead
                          isInvalid={billableValid} 
                          disabled={disabledFieldBillable === false ?  true : false}
                          className='dropdownComp'
                          id="basic-typeahead-single"
                          labelKey="name"
                          onChange={handleBillableClick}
                          options={Billable}
                          onFocus={handleOpenBillable}
                          onBlur={closeOutsideBillable}
                          open= {isOpenBillable}
                          clearButton
                          placeholder="Select Billable"
                          selected={workLogType}
                        />
                        { billableValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                        Please select billable
                         </span>}
                        </FormGroup>
                  </Col>
                  </Row>
            <FormGroup>
            <Label for="examplePassword">
                 Comments
                </Label>
                <Input
                value = {data?.description}
                disabled={disabledFieldComments === false ?  true : false}
                id="exampleEmail"
                name="name"
                onChange={handleComments}
                placeholder="Enter Comments"
                type="textarea"
                />
            </FormGroup>
            {
              <ul>
              {Object.keys(errMsg).map(errKey =>{
                if (errMsg[errKey] !== '') {
                  return(
                    <li id={errKey+'Err'} key={errKey} style={{'color':'red'}}>{errMsg[errKey]}</li>
                  )
                }
              })}
              </ul>}
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}
