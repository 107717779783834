import React, { useEffect, useState } from 'react'
import {Routes,Route, useLocation,Navigate,useNavigate} from 'react-router-dom'
import { ManageWorklog } from './pages/ManageWorklog'
import { Login } from './pages/Login'
import { ManageProject } from './pages/ManageProject'
import { ManageUser } from './pages/ManageUser'
import { ConfirmComp } from './pages/ConfirmComp'
import { ResetPassword } from './pages/ResetPassword'
import path from 'path'
import { ManageCompany } from './pages/ManageCompany'
import { ManageHolidays } from './pages/ManageHolidays'


const RoutesComp: React.FC = () => {
    const { pathname} = useLocation();
    return(
        <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/manage_worklog" element={<ManageWorklog/>} />
        <Route path="/manage_project" element={<ManageProject />} />
        <Route path="/manage_company" element={ <ManageCompany/> } />
        <Route path="/manage_user" element={ <ManageUser /> } />
        <Route path="/manage_holidays" element={ <ManageHolidays /> } />
        
        <Route path="/email/confirm/:uniqueId" element={<ConfirmComp/> }/>
        <Route path="/forgotPassword/:ID" element={<ResetPassword/> }/>
        {pathname.includes("/login") &&
            ((!localStorage.getItem("token")) ||(localStorage.getItem("token") == null)) ? 
            <Route path={pathname} element={<Navigate to='/login' replace={true}/>} /> :
            <Route path={pathname} element={<Navigate to='/manage_project' />} />
            }
            {!pathname.includes("/forgotPassword") &&
            !pathname.includes("/email/confirm") &&
            ((localStorage.getItem("token") == "null") || (!localStorage.getItem("token")))
              ? 
              <Route path={pathname} element={<Navigate to='/login' replace={true}/>} />
              // <Navigate to='/'/>
              : 
              <Route path='/manage_project' element={<Navigate to='/manage_project' />} />
             }
      </Routes>
    )
  }
export default RoutesComp