import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Navbar } from '../components/Navbar'
import { Button, Col, Row } from 'reactstrap'
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import './ManageHolidays.css'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { MdDelete } from "react-icons/md";
import { AddHolidays } from './AddHolidys'
import { ModalComp } from './Modal'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { deleteHoliday, fetchHolidayList, fetchUserDetails } from '../store/UserAction'
import { ToastContainer, toast } from "react-toastify";
import { DeleteModalComp } from './DeleteModal'
var qs = require('qs');


export const ManageHolidays: React.FC = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedYear, setSelectedYear] = useState([{id:1,name: 'This year'}]);
    const dispatch= useAppDispatch()
    const holiday = useAppSelector(state=>state.user.holidayList)
    const getHolidayList = useAppSelector(state=>state.user.getHolidayList)
    const getUserDetails = useAppSelector(state=>state.user.getUserDetails)
    const userDetails = useAppSelector(state=>state.user.userDetails)
    const holidayAdded = useAppSelector(state=>state.user.holidayAdded)
    const [holidayList, setHolidayList] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deletedRow, setDeletedRow] = useState()
    const [userInfo, setUserInfo] = useState({})

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    useEffect(() => {
      const currentYear = new Date().getFullYear();
        if(holidayAdded === true){
          toast.success("Holiday added successfully!", {
            position: "top-right",
          });
          setShowModal(false)
        }
        if(getHolidayList){
          setHolidayList(holiday)
        }
        if(getUserDetails){
            setUserInfo(userDetails)
        }
        let obj = {
          fromDate :formatDate(new Date(currentYear, 0, 1)), 
          toDate: formatDate(new Date(currentYear, 11, 31)),
          userId: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        }
        const queryParam = qs.parse(obj);
        dispatch<any>(fetchHolidayList(queryParam));
        dispatch<any>(fetchUserDetails(JSON.parse(localStorage.getItem('user'))?.id));
        setHolidayList(holiday)
        setUserInfo(userDetails)
     },[dispatch,getHolidayList,getUserDetails])

    const columns=useMemo(
        () => [
        {
        header : 'From Date',
        accessorFn: (row) => {
         return(
            `${row.fromDate}`
         )
        }
       },
       {
        header : 'To Date',
        accessorFn: (row) => {
         return(
          `${row.toDate}`
             )
        }
       },
       {
        header : 'No. of Days',
        accessorFn: (row) => {
          return(
            `${row?.noOfDays}`
           )
        }
       },
      ],
      [],
      );
    const handleDelete =(row) =>{
      setShowDeleteModal(true)
      setDeletedRow(row?.original)
    }
    const handleDeleteRow = () =>{
      let id = deletedRow ? deletedRow['id'] : 'null'
      dispatch<any>(deleteHoliday(id));
      setShowDeleteModal(false)
    }
    const openAddHolidayModal = () =>{
        setShowModal(true)
    }
    const closeModal = () =>{
        setShowModal(false)
        setShowDeleteModal(false)
    }
    const handleYearChange = (e) =>{
      const currentYear = new Date().getFullYear();
      const lastYear = new Date().getFullYear() - 1;
      setSelectedYear(e)
      if(e[0] !== undefined){
      if(e[0].name === 'Last year'){
        let obj = {
          fromDate :formatDate(new Date(lastYear, 0, 1)),
          toDate: formatDate(new Date(lastYear, 11, 31)),
          userId: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        }
        const queryParam = qs.parse(obj);
        dispatch<any>(fetchHolidayList(queryParam));
      }
      else if(e[0].name === 'This year'){
        let obj = {
          fromDate :formatDate(new Date(currentYear, 0, 1)), 
          toDate: formatDate(new Date(currentYear, 11, 31)),
          userId: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        }
        const queryParam = qs.parse(obj);
        dispatch<any>(fetchHolidayList(queryParam));
      }
     }
     else{
      
     }
    }
    const table = useMaterialReactTable({
        columns :  columns,
        data : holiday,
        initialState: { pagination:{pageSize: 5,pageIndex: 0},columnVisibility: { id: false } },
        muiTableBodyRowProps: ({ row }) => ({
          //conditionally style expanded rows
          sx: {
            backgroundColor:  'white',
          },
        }),
        displayColumnDefOptions: {
          'mrt-row-actions': {
            size: 120, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
            grow: false,
            },
        },
        enableTopToolbar: false,
        enableSorting:false,
        enableColumnActions:false,
      
        enableRowActions: true,
        renderRowActions: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
                {/* <RiPencilFill  style={{cursor:  'pointer' , width: '25px' , height: '25px'}} /> */}
               
                <MdDelete id='deleteIcon' style={{cursor: 'pointer'}} onClick={() => handleDelete(row)}/>
           </Box>    
        ),
      });
    let formTemplate = <AddHolidays formType="add" leaveBalance={(userDetails['allocatedHolidayBalance']-userDetails['noOfHolidaysTaken'])} rowData={{}} closeModal={closeModal}/>
    return (
    <Fragment>
          {showModal === true &&
        <ModalComp isOpen={showModal} modalTitle='Add Holidays' id="addWorklog" modalBody={formTemplate} toggle={closeModal}/>
        }
         {showDeleteModal === true &&
         <DeleteModalComp isOpen={showDeleteModal} modalBody={'Are you sure you want to delete the value?'} toggle={closeModal} handleDelete={handleDeleteRow}/>
         }
        <Navbar/>
        <ToastContainer />
        <div className='holidaysContainer'>
          <Row style={{paddingTop: '10px', paddingBottom: '40px'}}>
              <Col style={{maxWidth: '20%'}}>
                  <Card sx={{ minWidth: 175,backgroundColor: 'rgb(230, 236, 255)' }}>
                    <CardContent>
                      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 18 }}>
                          <b>Leaves Balance</b>
                      </Typography>
                      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 18 }}>
                          <b>{(userDetails['allocatedHolidayBalance']-userDetails['noOfHolidaysTaken'])}</b>
                      </Typography>
                    </CardContent>
                  </Card> 
              </Col>
              <Col style={{maxWidth: '20%'}}>
                    <Card sx={{ minWidth: 175,backgroundColor: 'rgb(230, 236, 255)' }}>
                      <CardContent>
                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 18 }}>
                            <b>Leave Taken</b> 
                          </Typography>
                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 18 }}>
                              <b>{userDetails['noOfHolidaysTaken']}</b>
                          </Typography>
                        </CardContent>
                      </Card>
              </Col>
              <Col>
                <Button color="secondary" style={{ marginLeft: '10px' ,float: 'inline-end'}} onClick={openAddHolidayModal}>Add Holidays</Button>
              </Col>
            </Row>
          <Row>
            <Col style={{maxWidth: '20%',paddingBottom: '20px'}}>
                  <Typeahead
                    className='dropdownComp'
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleYearChange}
                    options={[{id: 1, name:'This year'},{id: 2, name:'Last year'}]}
                    clearButton
                    placeholder="Select year"
                    selected={selectedYear}
                  />
              </Col>
          </Row>
          <Row><b>List Of Holidays</b></Row>
          <Row style={{padding: '10px'}}>
              <MaterialReactTable
                table={table}
                />
          </Row>
      </div>
    </Fragment>
  )
}
