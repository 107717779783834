import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Col, Row } from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addCompany,  addHoliday,  editCompany} from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import DatePicker, { registerLocale } from 'react-datepicker';
import './ManageUser.css'
import moment from 'moment';

type Props = {
    formType : string;
    rowData ?: any;
    addTableData ?: any;
    closeModal ?: any;
    leaveBalance ?: any;
}
type FormDataProps ={
  fromDate ?: any;
  toDate ?: any;
  noOfDays ?: any;
}

export const AddHolidays: React.FC<Props>  = ({formType,rowData,closeModal,leaveBalance}: Props) =>  {
const [data, setData] =useState<FormDataProps>(rowData);
const loading = useAppSelector(state=>state.user.loading);
const dispatch=useAppDispatch()
const [holidayFromDate,setHolidayFromDate] = useState()
const [holidayToDate,setHolidayToDate] = useState()
const [daysBetween, setDaysBetween] = useState(0);
const [fromDateValid, setFromDateValid] = useState(false)
const [toDateValid, setToDateValid] = useState(false)
const [errMsg , setErrMsg] = useState('')

useEffect(()=>{

},[])

const handleHolidayFromDate = (date) =>{
    setHolidayFromDate(date)
}
 const handleHolidayToDate = (date) =>{
    setHolidayToDate(date)
    const days = calculateDaysBetween(holidayFromDate, date);
    if(leaveBalance < days){
      setErrMsg('Insufficient holiday balance')
    }
    else{
      setErrMsg('')
    }
   setDaysBetween(days)
}

const onSubmit = (e) =>{
  e.preventDefault()
 let flag = false
  if(holidayFromDate === undefined){
    flag = true
    setFromDateValid(true)
  }
  if(holidayToDate === undefined){
    flag = true
    setToDateValid(true)
  }
  if(errMsg !== ''){
    flag = true
  }
  if(!flag){
    let fromDate = (moment(new Date(holidayFromDate)).format('DD/MM/YYYY'));
    let toDate =  (moment (new Date(holidayToDate)).format('DD/MM/YYYY'));
    let obj = {
      user: {
        id: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
        },
      fromDate: fromDate,
      toDate: toDate,
      noOfDays: daysBetween
    }
    dispatch<any>(addHoliday(obj));
  }
}
const calculateDaysBetween = (start, end) => {
    if (!start || !end) return 0;

    let count = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      // Count only weekdays
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  };
const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;  // Disable Sundays (0) and Saturdays (6)
  };
return (
    <Fragment>
       <Form  onSubmit={onSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="examplePassword">
                        From Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                        </Label>
                        <DatePicker 
                        locale="en-gb"
                        selected={holidayFromDate} 
                        isClearable
                        filterDate={isWeekday} 
                        onChange={(date) => handleHolidayFromDate(date)} 
                        dateFormat="dd/MM/yyyy"/>
                        { fromDateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                            Please select date
                        </span>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="examplePassword">
                        To Date <span style={{color: '#A0A0A0'}}>(DD/MM/YYYY)</span>
                        </Label>
                        <DatePicker 
                        id='date'
                        locale="en-gb"
                        selected={holidayToDate} 
                        minDate={holidayFromDate}
                        filterDate={isWeekday}
                        isClearable
                        onChange={(date) => handleHolidayToDate(date)} 
                        dateFormat="dd/MM/yyyy"/>
                        { toDateValid && <span style={{fontSize: 'small' , color: '#dc4c64'}}>
                            Please select date
                        </span>}
                    </FormGroup>
                  </Col>
            </Row>
            <Row><FormGroup>
                <Label for="examplePassword">
                 Number Of Days
                </Label>
                <Input
                        value = {daysBetween !== 0 ? daysBetween: ''}
                        disabled={true}
                        id="exampleEmail"
                        name="name"
                        type="number"
                        />
            </FormGroup></Row>
             { errMsg !== '' &&
              <li  style={{'color':'red'}}>{errMsg}</li>
              }
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}

